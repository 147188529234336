
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'
import useAuth from '../../../../app/modules/auth/hooks/useAuth'
import { APP_ROUTES } from '../../../../app/routing/routes'
// import useBrowserEventProvider from '../../../../app/components/BrowserEventProvider/useBrowserEventProvider'


const HeaderUserMenu: FC = () => {
  const { currentUser, currentRole, permissions } = useAuth()
  // const { sendTabEvent, BROADCAST_EVENT } = useBrowserEventProvider()
  // const handleLogout = () => {
  //   sendTabEvent(BROADCAST_EVENT.LOGOUT_EVENT)
  // }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={currentUser?.profile_image || toAbsoluteUrl('media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.user_name || currentUser?.user_id}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentRole}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={APP_ROUTES.PROFILE} className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      {
        (permissions?.['Users']) &&
        <div className='menu-item px-5'>
          <Link to={APP_ROUTES.USERS} className='menu-link px-5'>
            Manage Users and Roles
          </Link>
        </div>
      }
      {
        permissions?.['Settings'] && <div className='menu-item px-5 my-1'>
          <Link to={APP_ROUTES.SETTINGS} className='menu-link px-5'>
            Settings
          </Link>
        </div>
      }
      <div className='menu-item px-5 my-1'>
        <Link to={APP_ROUTES.HELP} className='menu-link px-5'>
          Help
        </Link>
      </div>
      <div className='menu-item px-5'>
      <Link to={APP_ROUTES.LOGOUT} className='menu-link px-5'>
          Sign Out
        </Link>
        {/* <button onClick={handleLogout} className=' btn btn-flat w-100 menu-link px-5'>
          Sign Out
        </button> */}
        {/* <a onClick={logout} className='menu-link px-5'>
          
        </a> */}
      </div>
    </div>
  )
}

export { HeaderUserMenu }
