import { useDispatch, useSelector } from "react-redux";
import { allPermissions, currentRoleSelector, logoutUser, selectCurrentUser } from "../features/auth.slice";
import { saveAuth, removeAuth, isAuthenticated } from "../AuthHelper";


const useAuth = () => {
    const currentUser = useSelector(selectCurrentUser)
    const currentRole = useSelector(currentRoleSelector)
    const permissions = useSelector(allPermissions)
    const isForceChangePassword = currentUser?.force_password_change === 1
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(logoutUser())
    }
    const _isAuthenticated = () => {
        return isAuthenticated()
    }
    return { logout, currentUser, saveAuth, removeAuth, currentRole, isAuthenticated: _isAuthenticated, permissions, isForceChangePassword }
}

export default useAuth