// cookieUtils.js
import { Cookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
const isProduction = process.env.NODE_ENV === 'production';

const defaultCookieOptions: CookieSetOptions = {
    path: '/',
    maxAge: 3600 * 24, // 
    secure: isProduction, // Ensures cookie is only sent over HTTPS
    sameSite: true, // Helps prevent CSRF
}
const cookies = new Cookies();

// Getter function
export const getSession = (key: string) => {
    if (!cookies.get(key))
        return
    return JSON.parse(cookies.get(key));
};


export const getToken = (key: string) => {
    if (!cookies.get(key))
        return
    return (cookies.get(key));
};

// Setter function
export const setSession = (key: string, value: any, options?: CookieSetOptions) => {
    cookies.set(key, JSON.stringify(value), {
        ...defaultCookieOptions,
        ...options
    });
};

export const setToken = (key: string, value: string, options?: CookieSetOptions) => {
    cookies.set(key, (value), {
        ...defaultCookieOptions,
        ...options
    });
};


// Remove function
export const removeSession = (key: string, options?: CookieSetOptions) => {
    cookies.remove(key, options);
};

export const setEncodedUrl = (url: string) => {
    try {
        if (url !== '' && !url.includes('logout') && url !== '/' && !url.includes('auth')) {
            const encodedUrl = encodeURIComponent(url);
            sessionStorage.setItem('redirectUrl', encodedUrl);
        } else
            sessionStorage.removeItem('redirectUrl');

    } catch (error) {
        console.error(error);
    }
};

export const getDecodedUrl = () => {
    try {
        const encodedUrl = sessionStorage.getItem("redirectUrl");
        if (encodedUrl) {
            const url = decodeURIComponent(encodedUrl);
            if (url !== '' && !url.includes('logout') && url !== '/' && !url.includes('auth')) {
                return url
            }
            sessionStorage.removeItem('redirectUrl');
            return ''
        }
        return '';
    } catch (error) {
        console.error(error);
    }

};

// Example usage:
// getCookie('myCookieName');
// setCookie('myCookieName', 'cookieValue', { path: '/', maxAge: 3600 });
