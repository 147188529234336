
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import AsideMenuHeaderTitle from './AsideMenuHeaderTitle'
import { Fragment, } from 'react'

import { IModules } from '../../../../app/pages/moduleCreator/models/Modules.interfaces'
import { formatNavigation } from '../../../../app/pages/moduleCreator/utils/helpers'
import { useDynamicRoutes } from '../../../../app/routing/DynamicRouteProvider'
import { routeComponentMap } from '../../../../app/routing/routeComponents'



const checkChildPresent = (child: IModules[]): boolean => {
  return child.filter(child => {

    if (child.type === 'list') {
      if (child.children)
        return checkChildPresent(child.children)
      else {
        return child.element && routeComponentMap[child.element]
      }
    } else
      return child.element && routeComponentMap[child.element]

  }).length > 0
};

const getRoutesByRoles = (routes: IModules[], hasBullet = false) => {
  return routes
    .filter((route: IModules) => !(route?.sidebarOptions?.hidden && route.type !== 'header'))
    .map(({ sidebarOptions, title, children, type, ...route }: IModules) => {

      if (type === 'header' && children && checkChildPresent(children)) {
        return <Fragment key={`path-to-${route.path || title}`} >
          {!sidebarOptions?.hidden && <AsideMenuHeaderTitle title={title || 'Link'} {...route} />}
          {getRoutesByRoles(children, false)}
        </Fragment>
      }

      if (type === 'list' && children && checkChildPresent(children)) {
        return <AsideMenuItemWithSub key={`path-to-${route.path || title}`} to={route.path as string} title={title || 'Link'} {...sidebarOptions} hasBullet={hasBullet}>
          {getRoutesByRoles(children, false)}
        </AsideMenuItemWithSub>
      }
      if (route.element && routeComponentMap[route.element])
        return <AsideMenuItem
          key={`path-to-${route.path || title}`}
          to={route.path as string}
          title={title || 'Link'}
          hasBullet={!sidebarOptions?.icon}
          {...sidebarOptions}
        />
    })

};



export function AsideMenuMain() {
  const { dynamicRoutes, isLoading } = useDynamicRoutes()


  if (isLoading || !dynamicRoutes)
    return <AsideMenuItem
      key={`loading`}
      to={''}
      title={'Loading...'}
      hasBullet={false}
    />
  const routes = formatNavigation(dynamicRoutes as IModules[]) || [];
  return <>
    {getRoutesByRoles([...routes,])}
  </>



}
